@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&display=swap');

.console {
    background-color: #fff;
    margin: 10px 20px 10px 20px;
}

.header {
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd !important;
    width: 100%;
}

.logo > img {
    float: left;
    margin-right: 30px;
    height: 60px;
}

.navbar-container {
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-header {
    color: #333;
    font-size: 15pt;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    padding: 0;
    margin-top: -14px;
    margin-left: 100px !important;
    margin-bottom: 0px !important;
    letter-spacing: 0px;
    width: 60%;
}

.nav-header > div {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
    padding: 0 !important;
}

.nav-header > div:first-child {
    font-weight: 600;
}

.nav-header > div:last-child {
    margin-top: -40px !important;
    font-size: 10pt;
}

.side-bar {
    background-color: #fff;
    box-shadow: 2px 2.5px 5px #ddd;
}

.sidebar-item {
    margin-top: 10px;
    width: 100%;
}

.sidebar-button {
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    color: black;
}

.sidebar-button:hover,
.sidebar-button:focus {
    color: black;
}

.action-container {
    float: right;
    color: #fff;
}

.user-info {
    display: flex;
    align-items: center;
}

button.user-info {
    color: black;
    float: right;
}

button.user-info:hover {
    color: black;
}

.alert-box {
    left: calc(25vw);
    width: 50vw;
}

.layout--menu {
    width: 100%;
    /* max-width: 35%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.layout--menu-right {
    /* max-width: 30%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom--space {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* max-width: 20%; */
}

.lfx-affix > .ant-affix {
    z-index: 2000;
}

.company-space {
    justify-content: flex-end;
    max-width: 30vw;
    width: 30vw;
    gap: 12px !important;
}

.company-space > .ant-space-item:is(:nth-child(1)) {
    max-width: 5%;
    justify-content: center;
    display: flex;
}

.company-space > .ant-space-item:is(:nth-child(2)) {
    max-width: 50%;
}

.company-space > .ant-space-item:is(:nth-child(3)) {
    max-width: 45%;
}

.company-space .ant-space-item .ant-space {
    width: 100%;
}
