.purchase-document-popover-overlay .ant-popover-inner-content {
    width: 100%;
    max-width: 300px;
}

.add-table .ant-table-content {
    min-height: 300px;
    max-height: 300px;
    overflow: scroll !important;
}

.add-table .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 100;
}
