body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.leaflet-container {
    width: 100%;
    height: 100%;
    /* height: 230px; */
}

.flex-container-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-container-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
}

.g-24 {
    gap: 24px;
}

.g-8 {
    gap: 8px;
}

.mb-12 {
    margin-bottom: 12px;
}

.ant-radio-button-wrapper {
    z-index: 0 !important;
}

.ant-popover-inner .ant-btn-primary {
    background-color: #972d47 !important;
    border-color: #972d47 !important;
}

.custom-space .ant-space-item {
    width: 100%;
}

.custom-space {
    width: 100%;
}
