button.header-icon {
    font-size: 18pt;
    margin-right: 15px;
}

button.header-icon span.anticon {
    color: black;
    margin-right: 10px;
}

button.header-icon span.anticon :active {
    color: black !important;
}

button.header-icon span.anticon :focus {
    color: black !important;
}

button.header-icon span.anticon :hover {
    color: black !important;
}

.navbar-popover .ant-popover-inner-content {
    padding: 0;
}

.navbar-popover .ant-list-item {
    padding-left: 16px;
    padding-right: 16px;
}

.navbar-popover .ant-popover-inner {
    padding: 0 !important;
}
