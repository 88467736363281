
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&display=swap');

.console {
  background-color: #fff;
  margin: 10px 20px 10px 20px;
}

.header {
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd !important;
  width: 100%;
}

.logo > img {
  float: left;
  margin: 15px -40px;
  height: 35px;  
}

.navbar-container {
  float: right;
  /* margin-top: -78px !important; */
}

.nav-header {
  color: #333;
  font-size: 15pt;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 500;
  padding: 0;
  margin-top: -14px;
  margin-left: 100px !important;
  margin-bottom: 0px !important;
  letter-spacing: 0px;
  width: 60%;
}

.nav-header > div {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  padding: 0 !important;
}

.nav-header > div:first-child {
  font-weight: 600;
}

.nav-header > div:last-child {
  margin-top: -40px !important;
  font-size: 10pt;
}

.side-bar {
  background-color: #fff;
  box-shadow: 2px 2.5px 5px #ddd;
}

.sidebar-item {
  margin-top: 10px;
  width: 100%;
}

.sidebar-button {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: left;
  width: 100%;
}

.action-container {
  float: right;
  color: #fff;
}

button.user-info {
  color: right;
  float: right;
  /* margin-right: -80px; */
}

button.user-info:hover {
  color: black;
}

.alert-box {
  left: calc(25vw);  
  width: 50vw;  
}

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
  }
  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }